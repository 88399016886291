import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { ColumnMode } from '@swimlane/ngx-datatable';
import {
  CateringHeadModel,
  CateringState,
  CateringTemplateConfig,
  getCateringSates
} from '../../../core-lib/models/catering-head.model';
import { I18nPipe } from '../../../core-lib/pipes/i18n.pipe';
import { getSessionUserIsCaterer } from '../../../session/reducers/session.reducer';
import { cateringTableActions } from '../../ngrx/actions/core-overview-table.actions';
import {
  CoreFeatureState,
  getConfigLocationNameById,
  getConfigLocationRoomNameById
} from '../../ngrx/reducers/core.store';
import { OverviewTableBase } from '../../utils/overview-table-base';

@Component({
  selector: 'lib-common-catering-table',
  templateUrl: './catering-table.component.html',
  styleUrls: ['./catering-table.component.scss'],
})
export class CateringTableComponent extends OverviewTableBase<CateringHeadModel> implements OnInit, AfterViewInit {
  columnMode = ColumnMode.force;

  @ViewChild('filterTemplate', {static: true})
  filterTemplate: TemplateRef<ElementRef>;

  @ViewChild('stateFilterTemplate', {static: true})
  stateFilterTemplate: TemplateRef<ElementRef>;

  @ViewChild('dateCellTemplate', {static: true})
  dateCellTemplate: TemplateRef<ElementRef>;

  @ViewChild('dateFilterTemplate', {static: true})
  dateFilterTemplate: TemplateRef<ElementRef>;

  @ViewChild('i18nTextCellTemplate', {static: true})
  i18nTextCellTemplate: TemplateRef<ElementRef>;

  @ViewChild('actionsCellTemplate', {static: true})
  actionsCellTemplate: TemplateRef<ElementRef>;

  @ViewChild('deliveryCellTemplate', {static: true})
  deliveryCellTemplate: TemplateRef<ElementRef>;

  @ViewChild('roomCellTemplate', {static: true})
  roomCellTemplate: TemplateRef<ElementRef>;

  @ViewChild('deliveryFilterTemplate', {static: true})
  deliveryFilterTemplate: TemplateRef<ElementRef>;

  states = getCateringSates();

  constructor(
    protected store$: Store<CoreFeatureState>,
    protected dialog: MatDialog,
    protected i18nPipe: I18nPipe,
  ) {
    super(
      store$,
      dialog,
      i18nPipe,
      cateringTableActions,
      'catering',
    );

    this.initSortFilterPageConfig = {
      filter: {
        id: '',
        state: '',
        cateringStartFrom: undefined,
        cateringStartUntil: undefined,
        location: '',
        room: '',
        startTimeFrom: undefined,
        startTimeUntil: undefined,
        participantCount: '',
        collectionByAuthor: undefined,
      },
      page: {
        number: 0,
        size: 20,
      },
      sort: {
        prop: 'id',
        dir: 'desc',
      },
      selection: undefined,
    };
  }

  isCaterer$ = this.store$.select(getSessionUserIsCaterer);

  createTemplates = (): CateringTemplateConfig => ({
    actions: {
      columnName: 'actions',
      cellTemplate: this.actionsCellTemplate,
      prop: 'publicId',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    publicId: {
      columnName: 'id',
      summaryTemplate: this.filterTemplate,
      prop: 'publicId',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    state: {
      columnName: 'state',
      summaryTemplate: this.stateFilterTemplate,
      cellTemplate: this.i18nTextCellTemplate,
      prop: 'state',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    startDateTime: {
      columnName: 'cateringStart',
      summaryTemplate: this.dateFilterTemplate,
      cellTemplate: this.dateCellTemplate,
      prop: 'startDateTime',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    endDateTime: {
      columnName: 'cateringEnd',
      summaryTemplate: this.dateFilterTemplate,
      cellTemplate: this.dateCellTemplate,
      prop: 'endDateTime',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    location: {
      columnName: 'location',
      summaryTemplate: this.filterTemplate,
      prop: 'location',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    roomName: {
      columnName: 'room',
      summaryTemplate: this.filterTemplate,
      cellTemplate: this.roomCellTemplate,
      prop: 'roomName',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    participantCount: {
      columnName: 'participantCount',
      summaryTemplate: this.filterTemplate,
      prop: 'participantCount',
      sortable: true,
      draggable: true,
      resizable: true,
    },
    collectionByAuthor: {
      columnName: 'collectionByAuthor',
      summaryTemplate: this.deliveryFilterTemplate,
      cellTemplate: this.deliveryCellTemplate,
      prop: 'collectionByAuthor',
      sortable: true,
      draggable: true,
      resizable: true,
    },
  })

  ngAfterViewInit(): void {
    this.templates$.next(this.createTemplates());
  }

  getLocationName$(locationId) {
    return this.store$.select(getConfigLocationNameById, {locationId});
  }

  getRoomName$(locationId, roomId) {
    return this.store$.select(getConfigLocationRoomNameById, {locationId, roomId});
  }

  getFormIdentifier(identifier: number, state: CateringState) {
    switch (state) {
      case 'CATERING_IN_PROGRESS':
      case 'CATERING_COMPLAINED':
      case 'CATERING_COMPLETE':
      case 'CATERING_APPROVAL_BY_AUTHOR':
        return identifier + 'c';
      default:
        return identifier;
    }
  }
}
